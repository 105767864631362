import Vue from "vue";
import mqtt from "mqtt";
import EventBus from "./bus.js";
import { forEach } from "lodash";

const MqttClient = new Vue({
    data() {
        return {
            client: null,
            subscribedTopics: [],
            topics: [],
        }
    },
    beforeDestroy() {
        this.client.end();
        // loop through topics and remove it from the event bus
        forEach(this.topics, (topic) => {
            EventBus.$off(topic);
        });
    },
    created() {
        this.client = mqtt.connect({
            servers: [
                {
                    host: process.env.VUE_APP_MQTT_URL,
                    port: process.env.VUE_APP_MQTT_PORT,
                },
            ],
            protocol: "wss",
            username: process.env.VUE_APP_MQTT_USER,
            password: process.env.VUE_APP_MQTT_PASS,
        });

        this.client.on("connect", () => {
        });
        this.client.on("message", (topic, message) => {
            // If the incoming topic is globus/vaveproxy/events/1aaceaf3-91e1-4024-be5f-f79f19ed73df/created, we want to loop through the subscribedTopics and see if any of them match, even  if they are a wildcard.
            forEach(this.subscribedTopics, (subscribedTopic) => {
                // replace # with .* to make it a regex
                // create a regex from the subscribedTopic string
                const regex = new RegExp(subscribedTopic.replace('#', '.*'))
                // check if the incoming topic matches the regex
                if (regex.test(topic)) {
                    EventBus.$emit(subscribedTopic, message);
                }
            });
        });
    },
    methods: {
        getClient() {
            return this.client;
        },
        getTopics() {
            return this.subscribedTopics;
        },

        subscribe(topic) {
            if (this.subscribedTopics.includes(topic)) {
                return;
            }
            this.subscribedTopics.push(topic);

            this.client.subscribe(topic);

            this.topics.push(topic);
        },
        publish(topic, message) {
            this.client.publish(topic, message);
        }
    }
});

export default MqttClient